import {
    FOOTNOTE_LINK_CLASSNAME,
    Footnotes,
    createFootnotesComponent,
} from '@vfde-brix/footnotes';
import {
    CONTENT_ID,
    FOOTNOTES_CONTAINER_ID,
} from '../constants';
import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/core';

/**
 * Initialize Footnotes
 */
export const initFootnotes = () => {
    const contentElement = document.getElementById(CONTENT_ID);
    const footnotesContainer = document.getElementById(FOOTNOTES_CONTAINER_ID);

    /* istanbul ignore if */
    if (!contentElement || !footnotesContainer) {
        return;
    }

    // mount the footnotes component
    const footnotes = createFootnotesComponent(footnotesContainer, NO_PATTERN_BUSINESS_LOGIC);

    connectFootnotes([contentElement]);

    const observerConfig = { attributes: false, childList: true, subtree: true };
    const observer = new MutationObserver(records => {
        const hasAddedNodes = records.some(record => record.type === 'childList' && record.addedNodes.length);

        /* istanbul ignore if */
        if (!hasAddedNodes) {
            // if no nodes were added, there can't be new footnotes
            return;
        }

        const targetElements = records.map(record => record.target) as HTMLElement[];

        connectFootnotes(targetElements);
    });

    // observe the whole content area for freshly injected sups
    observer.observe(contentElement, observerConfig);

    document.addEventListener('click', e => {
        const target = e.target as HTMLAnchorElement;
        const closestFootnoteLink = target.closest('a[href^="#footnote-"]');

        if (!closestFootnoteLink) {
            // click was not on a footnote-link
            return;
        }

        const targetFootnoteNumber = +closestFootnoteLink.getAttribute('href')!.trim().slice(1).replace('footnote-', '');

        /* istanbul ignore if */
        if (Number.isNaN(targetFootnoteNumber)) {
            return;
        }

        e.preventDefault();

        footnotes.scrollToFootnote(targetFootnoteNumber);
    });
};

const connectFootnotes = (contextElements: HTMLElement[]) => {
    // only get those sups which are not nested in an anchor already
    const sups = contextElements.flatMap(contextElement => Array.from(contextElement.querySelectorAll<HTMLElement>(':not(a) > sup')));

    for (const sup of sups) {
        const supNumber = parseInt(sup.textContent!.trim(), 10);

        /* istanbul ignore if */
        if (Number.isNaN(supNumber)) {
            // this sup does not seem to be a footnote so we ignore it
            return;
        }

        const anchor = document.createElement('a');

        anchor.className = sup.className;

        if (anchor.classList.length === 0) {
            // if the sup had no class, add the default footnote-link class
            anchor.classList.add(FOOTNOTE_LINK_CLASSNAME);
        }

        anchor.setAttribute('href', `#${Footnotes.getFootnoteId(supNumber)}`);
        anchor.appendChild(sup.cloneNode(true));

        sup.replaceWith(anchor);
    }
};
